<template>
  <section>
    <div class="row wow fadeIn">
      <div class="col-md-12 col-xl-12 mb-2">
        <form
          :disabled="(isUploading)"
          enctype="multipart/form-data"
          novalidate
        >
          <div class="dropbox">
            <div v-show="isUploading">
              <v-progress-linear
                indeterminate
                color="primary"
              />
            </div>
            <div
              v-show="(!isUploading)"
              style="width: 100%; height: 5px;"
            />
            <input
              type="file"
              multiple
              :disabled="(isUploading ? true : false || !enabled)"
              :accept="enabledAcceptFileTypes"
              class="input-file"
              @change="filesChange($event.target.name, $event.target.files)"
            >
            <p v-if="files.length === 0">
              {{ this.$props.label1 }} <br> {{ this.$props.label2 }}
            </p>
            <ul
              v-else
              class="pa-5"
              style="list-style: none; font-weight: 300;"
            >
              <li
                v-for="(file, index) in files"
                :key="index + file.name"
              >
                {{ index+1 }}. <b> {{ file.name }}</b> - {{ (file.size / 1024 / 1024).toFixed(2) }} MB
              </li>
            </ul>
          </div>
          <div class="text-right mt-5">
            <v-btn
              v-show="(!(files.length === 0))"
              text
              color="primary"
              :disabled="(isUploading ? true : false || !enabled)"
              class="btn-link btn-round btn-sm text-primary mr-5"
              :class="{ disabled: files.length === 0, displayNone: !enableClearButton}"
              @click.prevent="reset"
            >
              {{ this.$props.labelClear }} <i class="ml-1 fas fa-broom" />
            </v-btn>
            <v-btn
              v-show="(!(files.length === 0) || isUploading)"
              text
              color="primary"
              :disabled="(isUploading ? true : false || !enabled)"
              class="btn-link btn-round btn-sm text-primary"
              :class="{ disabled: files.length === 0, displayNone: !enableUploadButton}"
              @click.prevent="uploadFiles"
            >
              {{ this.$props.labelUpload }} <i class="ml-1 fas fa-file-upload" />
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

const STATUS_INITIAL = 'initial'
const STATUS_UPLOADING = 'uploading'
const STATUS_SUCCESS = 'upload-success'
const STATUS_FAILED = 'upload-failed'

export default {
  name: 'FileUploadComponent',
  components: {
  },
  props: {
    url: { type: String, default: '' },
    fieldName: { type: String, default: 'file' },
    uploadButton: { type: Boolean, default: true },
    clearButton: { type: Boolean, default: true },
    acceptFiles: { type: String, default: '*' },
    label1: { type: String, default: 'Drag files here' },
    label2: { type: String, default: 'or click to choose file' },
    labelClear: { type: String, default: 'CLEAR FILES' },
    labelUpload: { type: String, default: 'UPLOAD FILES' },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      uploadUrl: this.url,
      fileParamsName: this.fieldName,
      enableClearButton: this.clearButton,
      enableUploadButton: this.uploadButton,
      enabledAcceptFileTypes: this.acceptFiles,
      currentStatus: STATUS_INITIAL,
      enabled: !this.disabled,
      files: []
    }
  },
  computed: {
    fileCount () {
      return this.files.length
    },
    isUploading () {
      return this.currentStatus === STATUS_UPLOADING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  watch: {
    disabled: function (newValue) {
      this.enabled = !newValue
    },
    url: function (newValue) {
      this.uploadUrl = newValue
    }
  },
  methods: {
    reset () {
      this.files = []
      this.changeStatus(STATUS_INITIAL)
    },
    async uploadFiles () {
      const formData = new FormData()
      for (let index = 0; index < this.files.length; index++) {
        formData.append(this.fileParamsName, this.files[index])
      }
      this.changeStatus(STATUS_UPLOADING, formData)
      const response = await axios.post(
        this.uploadUrl,
        formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      if (response.status === 200) {
        this.changeStatus(STATUS_SUCCESS, formData)
        this.$emit('uploadFiles', response.data)
        this.reset()
      } else {
        this.changeStatus(STATUS_FAILED, response)
      }
    },
    filesChange (fieldName, fileList) {
      this.changeStatus(STATUS_INITIAL)
      if (!fileList.length) return
      this.files = Array
        .from(Array(fileList.length).keys())
        .map(x => fileList[x])
    },
    changeStatus (status, data) {
      console.log('change status', status)
      this.currentStatus = status
      this.$emit('changeStatus', status, data)
    },
    mounted () {
      this.reset()
    }
  }
}
</script>
<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: #f3f3f3;
  color: dimgray;
  padding: 10px 10px;
  min-height: 180px; /* minimum height */
  position: relative;
  cursor: pointer
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 180px;
  position: absolute;
  cursor: pointer
}

.dropbox:hover {
  background: #d3d3d3 /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.disabled {
  color: grey !important;
}
.displayNone {
  display: none !important;
}
</style>
