<template>
  <v-container
    id="issues"
    fluid
    tag="section"
  >
    <p  class="font-weight-light text-subtitle-1" style="color: rgba(0, 0, 0, 0.87);">
      The issue was created by <span class="font-weight-bold text-subtitle-1">{{issue.userName}}</span>
    </p>
    <v-form ref="form">
      <v-row>
        <v-col :cols="isNewIssue ? 10 : 10">
          <v-row>
            <v-col lg="2">
              <v-text-field
                v-model="issue.id"
                readonly
                disabled
                filled
                no-resize
                full-width
                label="Issue number"
              />
            </v-col>
            <v-col lg="10">
              <v-select
                v-model="issue.subject"
                :items="subjectList"
                label="Subject"
                :disabled="isClosed"
                :rules="[rules.required]"
                placeholder="Please select subject of issue"
                filled
                dense
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="issue.description"
            :disabled="isClosed"
            :rules="[rules.required, rules.min5]"
            filled
            no-resize
            full-width
            label="Description"
            counter="1024"
            maxlength="1024"
          />
        </v-col>
        <v-col :cols="isNewIssue ? 2 : 2">
          <v-text-field
            disabled
            filled
            :value="lastUpdate"
          />
          <v-text-field
            disabled
            filled
            :value="issueStatus"
          />
          <v-row v-show="isIssueMy(issue)">
            <v-col cols="6">
              <v-btn
                color="secondary"
                :disabled="isClosed || isNewIssue"
                class="white--text btn-center"
                @click="changeStatus('FINISH')"
              >
                Close
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                :disabled="isClosed"
                color="primary"
                class="white--text btn-center"
                @click="saveIssue()"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-show="!(isIssueMy(issue))"
            class="mt--1"
          >
            <v-col cols="6">
              <v-btn
                color="secondary"
                :disabled="isClosed || isDoneBySupport"
                class="white--text btn-center"
                @click="changeStatus('DONE_BY_SUPPORT')"
              >
                Done
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                :disabled="isClosed || isInProgress"
                color="primary"
                class="white--text btn-center"
                @click="changeStatus('IN_PROGRESS')"
              >
                Start
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <!-- Files -->
    <v-row v-show="!isNewIssue">
      <v-col cols="6">
        <ul style="list-style: none; font-weight: 300" class="pl-0 ml-0">
          <li v-for="(file, index) in files" :key="index" class="ma-1">
            <v-btn small color="primary" text @click="downloadFile(file)"><v-icon dark>mdi-download</v-icon></v-btn>
            <v-btn small color="red darken-1" text :disabled="(!isFileMy(file) || isClosed)" @click="deleteFile(file)"><v-icon dark>mdi-delete</v-icon></v-btn>
            <span class="font-weight-medium">{{file.fileName}}</span> <span class="font-italic">({{(file.size / 1024 / 1024).toFixed(2)}} MB)</span>
          </li>
        </ul>
      </v-col>
      <v-col cols="6" ref="file-drop-wrapper">
        <file-drop
          ref="file-drop"
          :disabled="isClosed"
          :url="fileUploadUrl()"
          @uploadFiles="uploadFiles" />
      </v-col>
    </v-row>

    <v-row v-show="!isNewIssue">
      <v-col cols="12">
        <hr class="h_line mb-1">
      </v-col>
    </v-row>
    <v-row
      v-for="item in comments"
      v-show="!isNewIssue"
      :key="item.id"
    >
      <v-col
        v-show="isCommentMy(item)"
        cols="3"
      />
      <v-col cols="8">
        <v-textarea
          v-model="item.comment"
          :disabled="isClosed || !isCommentMy(item) || (!isIssueMy(issue) && isDoneBySupport)"
          filled
          no-resize
          full-width
          rows="3"
          counter="512"
          maxlength="512"
        />
      </v-col>
      <v-col
        v-show="!isCommentMy(item)"
        cols="3"
      />
      <v-col
        cols="1"
        class="text-right"
      >
        <v-btn
          v-show="isCommentMy(item)"
          color="primary"
          :disabled="isClosed || !isCommentMy(item) || (!isIssueMy(issue) && isDoneBySupport)"
          class="white--text btn-center"
          right
          target="_blank"
          @click="saveComment(item)"
        >
          Edit Comment
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-show="!isNewIssue">
      <v-col cols="3" />
      <v-col cols="8">
        <v-textarea
          v-model="comment.comment"
          :disabled="isClosed || (!isIssueMy(issue) && isDoneBySupport)"
          filled
          no-resize
          full-width
          rows="3"
          label="Please put here your new comment"
          counter="512"
          maxlength="512"
        />
      </v-col>
      <v-col
        cols="1"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="isClosed || (!isIssueMy(issue) && isDoneBySupport)"
          class="white--text btn-center"
          right
          target="_blank"
          @click="createComment(comment)"
        >
          New Comment
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import rules from '@/shared/rules'
import DateTimeService from '@/service/DateTimeService'
import FileDrop from '@/components/FileDrop'

export default {
  name: 'IssuesDetails',
  components: {
    FileDrop
  },
  data: () => ({
    isNewIssue: false,
    issue: {
      id: null,
      subject: '',
      categoryKey: '',
      status: 'NEW',
      description: '',
      updateAt: new Date().getTime()
    },
    subjectList: [],
    comment: { },
    comments: [],
    files: [],
    rules: rules,
    resourcesIntervalId: null
  }),
  getters: {
    ...mapState(['user'])
  },
  computed: {
    issueStatus: function () {
      return this.issue.status
    },
    lastUpdate: function () {
      return DateTimeService.format(new Date(this.issue.updateAt))
    },
    isClosed: function () {
      return this.issue.status === 'FINISH'
    },
    isInProgress: function () {
      return this.issue.status === 'IN_PROGRESS'
    },
    isDoneBySupport: function () {
      return this.issue.status === 'DONE_BY_SUPPORT'
    }
  },
  beforeMount () {
    // check is new issue or exist
    this.isNewIssue = !!(this.$route.params.id && this.$route.params.id === '-1')
    this.issue.categoryKey = this.$route.params.category

    switch (this.issue.categoryKey) {
      case 'it': this.subjectList = [
        'Platform Issue',
        'Order/Execution Issue',
        'New account request',
        'Close account request',
        'Other']; break
      case 'acc': this.subjectList = [
        'Payout Request',
        'Payout statement issue',
        'Sterling reports Issue',
        'Eze reports Issue',
        'Other'
      ]; break
      case 'mgnt': this.subjectList = [
        'Risk Parameters Request',
        'Other']; break
      default: this.subjectList = []
    }
    if (!this.isNewIssue) {
      // get issues
      this.fetchIssue()
      // get comments
      this.fetchAllComments(this.$route.params.id)
      // get files
      this.fetchAllFiles()
    }

    this.resourcesIntervalId = setInterval(() => {
      this.fetchAllFiles()
      this.fetchAllComments(this.$route.params.id)
    }, 5000)
  },
  methods: {
    isIssueMy (issue) {
      return this.isNewIssue ? true : issue.createUser === this.$store.getters.user.accessId
    },
    isFileMy (file) {
      return file.createUser === this.$store.getters.user.accessId
    },
    isCommentMy (item) {
      return item.createUser === this.$store.getters.user.accessId
    },
    fileUploadUrl () {
      return `/api/v1/support/issues/${this.issue.id || this.$route.params.id}/files`
    },
    async changeStatus (status) {
      if (!this.isNewIssue) {
        axios.post(`/api/v1/support/issues/${this.issue.id}/change-status/${status}`)
          .then(r => {
            this.issue = r.data
          })
          .catch(e => {
            console.log(e)
          })
      }
    },
    async saveIssue () {
      if (this.$refs.form.validate()) {
        if (this.isNewIssue) {
          const response = await axios.post('/api/v1/support/issues', this.issue)
          this.isNewIssue = false
          this.issue = response.data
          const id = this.issue.id = response.data.id
          const category = this.$route.params.category
          await this.$router.replace(`/support/${category}/issue/${id}`)
        } else {
          axios.put(`/api/v1/support/issues/${this.issue.id}`, this.issue)
            .then(r => {
              console.log(r)
              this.issue = r.data
            })
            .catch(e => {
              console.log(e)
            })
        }
      }
    },
    async fetchIssue () {
      //
      const issueId = this.$route.params.id
      axios.get(`/api/v1/support/issues/${issueId}`)
        .then(r => {
          this.issue = r.data
        })
        .catch(e => {
          console.log(e)
          // TODO show dialog error
        })
    },
    async fetchAllFiles () {
      const response = await axios.get(`/api/v1/support/issues/${this.issue.id || this.$route.params.id}/files`)
      if (response.status === 200) {
        this.files = response.data
      }
    },
    async downloadFile (file) {
      const response = await axios.get(`/api/v1/support/issues/${this.issue.id || this.$route.params.id}/files/${file.fileId}/download`, { responseType: 'blob' })
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = file.fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    async uploadFiles (files) {
      this.files = files
    },
    async deleteFile (file) {
      const response = await axios.delete(`/api/v1/support/issues/${this.issue.id || this.$route.params.id}/files/${file.fileId}`)
      if (response.status === 200) {
        this.files = this.files.filter(f => f.fileId !== file.fileId)
      }
    },
    async fetchAllComments (issueId) {
      axios.get(`/api/v1/support/issues/comments/criteria?id=${issueId}&page1&size=100`)
        .then(r => {
          console.log(r)
          this.comments = r.data.content
        })
        .catch(e => {
          console.log(e)
        })
    },
    async createComment (comment) {
      await axios.post('/api/v1/support/issues/comments', { ...comment, issueId: this.issue.id })
        .then(r => {
          console.log(r)
        })
        .catch(e => {
          console.log(e)
        })
      this.comment.comment = ''
      await this.fetchAllComments(this.issue.id)
    },
    async saveComment (comment) {
      await axios.put(`/api/v1/support/issues/comments/${comment.id}`, { ...comment, issueId: this.issue.id })
        .then(r => {
          console.log(r)
        })
        .catch(e => {
          console.log(e)
        })
      await this.fetchAllComments(this.issue.id)
    }
  },
  beforeDestroy () {
    if (this.resourcesIntervalId !== null) clearInterval(this.resourcesIntervalId)
  }
}
</script>
<style scoped>
.h_line {
  width: 100%;
  height: 2px;
}

.mt--1 {
  margin-top: -12px;
}
</style>
